.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.centercontent {
  display: flex;
  justify-content: center;
  align-content: center;
}

.centercontent>* {
  align-self: center;
}

.fullpage {
  width: 100vw;
  height: 100vh;
}

span.welcomeuser{
  position: absolute;
  color: #777;
  font-size: 12px;
  top: 0;
}

#spinner {
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 10px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

.spinner-label{
  position: absolute;
  color: #fff;
  letter-spacing: 0.4px;
  font-size: 14px;
}

.flex-col{
  display: flex;
  flex-direction: column;
}

.flex-col .nav-item.end{
  margin-top: auto;
}

/* CUSTOM OVERRIDES OF ADMINLTE */
.user-panel .info {
  padding: 15px 5px 15px 10px;
}

.user-panel img {
  width: 3.5rem;
}

.user-panel.d-flex{
  align-items: center;
}

.overlay-wrapper .overlay{
  z-index: 9999;
}

.sidebar, .sidebar nav{
  flex: 1 1 auto;
}

/* ANIMATIONS */
@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}